import type { ReactElement } from 'react';
import { useState } from 'react';
import type { ISidebarLinkGroupProps } from './sidebar-link-group-interface';

export function SidebarLinkGroup({
  children,
  activeCondition,
}: ISidebarLinkGroupProps): ReactElement {
  const [open, setOpen] = useState<boolean>(activeCondition);

  const handleClick = () => {
    setOpen(!open);
  };

  return <li>{children(handleClick, open)}</li>;
}
