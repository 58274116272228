import type { IRouteItemProp } from 'core/interfaces/base';
import { lazy } from 'react';
import type { ISidebarItem } from '../partner-router/partner-router-interface';

const CustomerDashboard = lazy(async () => import('../../pages/customer-pages/dashboard/dashboard'));

export const customerSidebarItems: ISidebarItem[] = [
  {
    id: 1,
    name: 'Dashboard',
    icon: 'AiFillHome',
    path: '/customer',
  },
];

export const customerRouteItems: IRouteItemProp[] = [
  {
    path: '/',
    element: <CustomerDashboard />,
    id: 'home',
  },
];
