import type { IRouteItemProp } from 'core/interfaces/base';
import { lazy } from 'react';
import type { ISidebarItem } from './partner-router-interface';

const PartnerDashboard = lazy(async () => import('pages/backend/pages/partner-pages/dashboard/dashbord'));
const PartnerProfile = lazy(async () => import('pages/backend/pages/partner-pages/profile/profile'));
const PartnerAddTrailer = lazy(async () => import('pages/backend/pages/partner-pages/add-trailer/add-trailer'));
const PartnerTrailerList = lazy(async () => import('pages/backend/pages/partner-pages/trailer-list/trailer-list'));
const PartnerBooking = lazy(async () => import('pages/backend/pages/partner-pages/booking/booking'));
const PartnerExtensionRequest = lazy(async () => import('pages/backend/pages/partner-pages/extension-request/extension-request'));
const PartnerReviews = lazy(async () => import('pages/backend/pages/partner-pages/reviews/reviews'));
const PartnerNotification = lazy(async () => import('pages/backend/pages/partner-pages/notification/notification'));
const PartnerMessages = lazy(async () => import('pages/backend/pages/partner-pages/messages/messages'));
const PartnerBookingDateChangeRequests = lazy(async () => import('pages/backend/pages/partner-pages/booking-date-change-requests/booking-date-change-requests'));
const PartnerMyEarnings = lazy(async () => import('pages/backend/pages/partner-pages/my-earnings/my-earnings'));

export const partnerSidebarItems: ISidebarItem[] = [
  {
    id: 1,
    name: 'Dashboard',
    icon: 'AiOutlineHome',
    path: '/partner/dashboard',
  },
  {
    id: 2,
    name: 'Bookings',
    icon: 'AiOutlineCalendar',
    path: '/partner/my-booking',
  },
  {
    id: 3,
    name: 'Trailer',
    icon: 'AiFillCar',
    path: '/partner/add-trailer',
    submenu: [
      {
        id: 31,
        name: 'Add Trailer',
        path: '/partner/add-trailer',
      },
      {
        id: 32,
        name: 'Trailers List',
        path: '/partner/trailer-list',
      },
    ],
  },
  {
    id: 4,
    name: 'Messages',
    icon: 'AiOutlineMessage',
    path: '/partner/messages',
  },
  {
    id: 5,
    name: 'Extension Request',
    icon: 'AiOutlinePullRequest',
    path: '/partner/extension-request',
  },
  {
    id: 6,
    name: 'Date Change Request',
    icon: 'AiOutlineCalendar',
    path: '/partner/booking-date-change-requests',
  },
  {
    id: 7,
    name: 'Notifications',
    icon: 'AiOutlineNotification',
    path: '/partner/notification',
  },
  {
    id: 8,
    name: 'My Reviews',
    icon: 'AiFillStar',
    path: '/partner/reviews',
  },
  {
    id: 9,
    name: 'Earnings',
    icon: 'AiOutlineDollar',
    path: '/partner/my-earnings',
  },
  {
    id: 10,
    name: 'Profile',
    icon: 'AiOutlineUser',
    path: '/partner/profile',
  },
];

export const partnerRouteItems: IRouteItemProp[] = [
  {
    path: '/',
    element: <PartnerDashboard />,
    id: 'home',
  },
  {
    path: '/dashboard',
    element: <PartnerDashboard />,
    id: 'home',
  },
  {
    path: '/profile',
    element: <PartnerProfile />,
    id: 'profile',
  },
  {
    path: '/add-trailer',
    element: <PartnerAddTrailer />,
    id: 'add-trailer',
  },
  {
    path: '/trailer-list',
    element: <PartnerTrailerList />,
    id: 'trailer-list',
  },
  {
    path: '/my-booking',
    element: <PartnerBooking />,
    id: 'my-booking',
  },
  {
    path: '/extension-request',
    element: <PartnerExtensionRequest />,
    id: 'extension-request',
  },
  {
    path: '/reviews',
    element: <PartnerReviews />,
    id: 'reviews',
  },
  {
    path: '/notification',
    element: <PartnerNotification />,
    id: 'notification',
  },
  {
    path: '/messages',
    element: <PartnerMessages />,
    id: 'messages',
  },
  {
    path: '/booking-date-change-requests',
    element: <PartnerBookingDateChangeRequests />,
    id: 'booking-date-change-requests',
  },
  {
    path: '/my-earnings',
    element: <PartnerMyEarnings />,
    id: 'my-earnings',
  },
];
