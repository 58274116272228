import type { ReactElement } from 'react';
import LogoImage from 'assets/images/logo/logo.svg';
import { NavLink } from 'react-router-dom';

export function Logo(): ReactElement {
  return (
    <NavLink to="/">
      <img src={LogoImage} alt="big rentals" />
    </NavLink>
  );
}
