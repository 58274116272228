// iconUtils.ts
import type { FunctionComponentElement } from 'react';
import { createElement } from 'react';
import type { IconBaseProps, IconType } from 'react-icons/lib';
import * as ReactIcons from 'react-icons/ai';

export const getIconComponent = (
  iconName: keyof typeof ReactIcons | null,
):FunctionComponentElement<IconBaseProps> | null => {
  if (iconName === null) return null;
  const iconComponent: IconType = ReactIcons[iconName];

  return createElement(iconComponent);
};
