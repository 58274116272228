import { useQuery } from '@tanstack/react-query';
import { assetsApi } from 'core/api/assets.api';
import { queryKeys } from '../queryKeys';

export const useGetAssetCalendar = (
  assetId = '',
  startDate = '',
  endDate = '',
) => useQuery({
  queryKey: [queryKeys.assetCalendar, assetId, startDate, endDate],
  queryFn: () => assetsApi.getAssetCalendar(assetId, startDate, endDate),
  enabled: Boolean(assetId),
  staleTime: 1000 * 60 * 10,
});
