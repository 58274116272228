import type { ReactElement, ReactNode } from 'react';
import React, { useState, useEffect, createContext, useCallback, useMemo } from 'react';
import { debounce } from 'utils';

interface SettingContextData {
  location: any;
  setLocation: React.Dispatch<React.SetStateAction<any>>;
  debouncedSetLocation: React.Dispatch<React.SetStateAction<any>>;
  locationZoom: number;
  setLocationZoom: React.Dispatch<React.SetStateAction<any>>;
  clientInfo: any;
  setClientInfo: React.Dispatch<React.SetStateAction<any>>;
  showTopHeader: boolean;
  setShowTopHeader: React.Dispatch<React.SetStateAction<boolean>>;
  currentCity: string,
  setCurrentCity: React.Dispatch<React.SetStateAction<string>>;
  debouncedSetCurrentCity: React.Dispatch<React.SetStateAction<string>>;
}

export const SettingContext = createContext<SettingContextData>({
  location: {},
  setLocation: () => {},
  debouncedSetLocation: () => {},
  locationZoom: 9,
  setLocationZoom: () => {},
  clientInfo: {},
  setClientInfo: () => {},
  showTopHeader: true,
  setShowTopHeader: () => {},
  currentCity: '',
  setCurrentCity: () => {},
  debouncedSetCurrentCity: () => {},
});

export interface ISettingProviderProperties {
  children: ReactNode;
}

function SettingProvider({ children }: ISettingProviderProperties):ReactElement {
  const [showTopHeader, setShowTopHeader] = useState(true);
  const [clientInfo, setClientInfo] = useState({});
  const [currentCity, setCurrentCity] = useState<string>('');
  const [locationZoom, setLocationZoom] = useState<number>(9);

  const [location, setLocation] = useState(() => {
    const storedLocation = localStorage.getItem('Location');
    return storedLocation ? JSON.parse(storedLocation) : {};
  });

  const debouncedSetLocation = useMemo(() => debounce(setLocation, 333), []) ;

  const debouncedSetCurrentCity = useMemo(() => debounce(setCurrentCity, 333), []) ;

  useEffect(() => {
    localStorage.setItem('Location', JSON.stringify(location));
  }, [location]);

  const contextData: SettingContextData = {
    location,
    setLocation,
    debouncedSetLocation,
    locationZoom,
    setLocationZoom,
    clientInfo,
    setClientInfo,
    showTopHeader,
    setShowTopHeader,
    currentCity,
    setCurrentCity,
    debouncedSetCurrentCity,
  };

  return (
    <SettingContext.Provider value={contextData}>
      {children}
    </SettingContext.Provider>
  );
}

export default SettingProvider;
