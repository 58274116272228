import axios from 'axios';

const setAccessToken = () => {
  const accessToken = localStorage.getItem('TOKEN') ?? null;
  const employee = JSON.parse(localStorage.getItem('USER') as string) || null;
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  axios.defaults.headers.common.apiCallerSource = 'MARKETPLACE';
  axios.defaults.headers.common.portalPath = window.location.pathname;
  axios.defaults.headers.common['RD-TenantID'] = process.env.REACT_APP_API_TENANT_HEADER ?? '';

  if (employee) {
    axios.defaults.headers.common.apiCallerId = employee?.employeeId;
  } else {
    delete axios.defaults.headers.common.apiCallerId;
  }
};

const getAuthToken = async () => axios.post(`${process.env.REACT_APP_BASE_URL ?? ''}auth/token`, {
  clientId: process.env.REACT_APP_API_USER_NAME,
  secret: process.env.REACT_APP_API_SECRET,
  locationId: 0,
});

const ISSERVER = typeof window === 'undefined';

if (!ISSERVER) {
  setAccessToken();
}

axios.interceptors.request.use((config) => {
  const settingString = localStorage.getItem('Setting');
  const setting = settingString ? JSON.parse(settingString) : null;
  //@ts-ignore
  if (setting && setting.actAsTenant && !config.url.includes('\customer')) config.headers.common['ACT-Tenant'] = setting.actAsTenant;
  return config;
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
  setAccessToken,
  getAuthToken,
};
