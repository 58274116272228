import { setFilterDate } from "core/store/slices/settingSlice";
import store from "core/store/store";
import dayjs from "dayjs";

export interface FilterDates {
  pickupDate?: Date | string;
  pickupTime?: string;
  returnDate?: Date | string;
  returnTime?: string;
};

export const stringifyFilterDate = (date: Date) => dayjs(date).format('MM/DD/YYYY');

export const dayjsifyFilterDate = (date: Date | string) => date instanceof Date ? dayjs(date) : dayjs(date, 'MM/DD/YYYY');

export const isPickupAfterReturn = (
  pickupDate: Date | string,
  returnDate: Date | string,
) => dayjsifyFilterDate(pickupDate).isAfter(dayjsifyFilterDate(returnDate).endOf('day'))

export const setFilterDates = ({ pickupDate = '', returnDate = '', ...rest }: FilterDates) => {
  const retDate = pickupDate && returnDate && isPickupAfterReturn(pickupDate, returnDate) ? '' : returnDate;

  store.dispatch(
    setFilterDate({
      pickupDate: typeof pickupDate === 'string' ? pickupDate : stringifyFilterDate(pickupDate),
      returnDate: typeof retDate === 'string' ? retDate : stringifyFilterDate(retDate),
      ...rest,
    })
  );
};
