import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { assetsApi } from 'core/api/assets.api';

export interface AssetsOptionsProps {
  pickupDate: string;
  returnDate: string;
  lat: string;
  lng: string;
  sort?: string;
  assetIds?: string[];
  assetTypeIds?: string[];
  enabled?: boolean;
}

export const useGetMapAssetsOptions = ({
  pickupDate,
  returnDate,
  lat,
  lng,
  sort = 'BEST_MATCH',
  assetIds,
  assetTypeIds,
  enabled = false,
}: AssetsOptionsProps) => useQuery({
  queryKey: [
    queryKeys.assetsOptions,
    pickupDate,
    returnDate,
    lat,
    lng,
    sort,
    assetIds,
    assetTypeIds,
  ],
  queryFn: () => assetsApi.getAssetsOptions(
    pickupDate,
    returnDate,
    lat,
    lng,
    sort,
    assetIds,
    assetTypeIds,
  ),
  staleTime: 1000 * 60 * 1,
  enabled,
});

export const useGetFilterAssetsOptions = ({
  pickupDate,
  returnDate,
  lat,
  lng,
  sort = 'BEST_MATCH',
  assetIds,
  assetTypeIds,
  enabled = false,
}: AssetsOptionsProps) => useQuery({
  queryKey: [
    queryKeys.assetsOptions,
    pickupDate,
    returnDate,
    sort,
    assetIds,
    assetTypeIds,
  ],
  queryFn: () => assetsApi.getAssetsOptions(
    pickupDate,
    returnDate,
    lat,
    lng,
    sort,
    assetIds,
    assetTypeIds,
  ),
  staleTime: 1000 * 60 * 5,
  enabled,
});
