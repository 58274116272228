/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import http from './httpService';

class AssetsApi {
  headers = {
    'accept': 'application/json, text/plain, */*',
    'content-type': 'application/json',
  };

  async getAssetsOptions1(
    sortType: any,
    searchTxt: any,
    pickupDate: any,
    returnDate: any,
    pickupTime: any,
    returnTime: any,
    availFlag: any,
    selAssetType: any,
    lat: any,
    lng: any,
  ) {
    const sortValue = sortType.sortType || '';
    const sortOrder = sortType.sortOrder || '';
    let assetTypeAry = '';

    selAssetType.forEach((assetType:any) => {
      assetTypeAry += `&assetTypeId=${assetType}`;
    });

    const url = `${process.env.REACT_APP_BASE_URL
    }asset/search?pickupStr=${pickupDate}&returnStr=${returnDate}&pickupTime=${pickupTime}&returnTime=${returnTime}&sort=${sortValue}&sortOrder=${sortOrder}&searchFilter=${searchTxt}&filterAvailable=${availFlag}${assetTypeAry}&lat=${lat}&lng=${lng}`;

    return http.get(url);
  }

  async getSimilarAsset(
    assetId: any,
    pickupDate: any,
    returnDate: any,
    assetType: any,
    locationId: any,
    length: any,
    radius: any,
    pageCount: any,
  ) {
    const url = `${process.env.REACT_APP_BASE_URL
    }asset/list/similar?assetId=${assetId}&pickupStr=${pickupDate}&returnStr=${returnDate}&assetType=${assetType}&locationId=${locationId}&length=${length}&radius=${radius}&pageCount=${pageCount}`;

    return http.get(url);
  }

  async getAssetsOptions(
    pickupDate: any,
    returnDate: any,
    lat: any,
    lng: any,
    sort: any,
    assetIds: any = [],
    assetTypeIds: any =[],
  ) {
    let assetQueryStr = 'assetId=';

    assetIds && assetIds.forEach((assetId: any, ind: any) => {
      assetQueryStr
        += ind === assetIds.length - 1
          ? assetId
          : `${assetId}&assetId=`;
    });
    let assetTypeQueryStr = 'assetTypeId=';

    assetTypeIds && assetTypeIds.forEach((assetTypeId: any, ind: any) => {
      assetTypeQueryStr
        += ind === assetTypeIds.length - 1
          ? assetTypeId
          : `${assetTypeId}&assetTypeId=`;
    });

    const url = `${process.env.REACT_APP_BASE_URL
    }asset/search?pickupStr=${pickupDate}&returnStr=${returnDate}&lat=${lat}&lng=${lng}&sort=${sort}&${assetQueryStr}&${assetTypeQueryStr}`;

    return http.get(url);
  }

  async checkAssetAvailable(
    assetID:any,
    pickupDate: any,
    returnDate: any,
    pickupTime: any,
    returnTime: any,
  ) {
    return http.get(
      `${process.env.REACT_APP_BASE_URL
      }asset/search?pickupStr=${pickupDate}&returnStr=${returnDate}&pickupTime=${pickupTime}&returnTime=${returnTime}&assetId=${assetID}`,
    );
  }

  async getAssetRate(assetId: any) {
    const config = {
    };

    return http.get(
      `${process.env.REACT_APP_BASE_URL
      }asset/${assetId}/rate`,
      config,
    );
  }

  async getBookingFee(
    assetID:any,
    pickupDate:any,
    pickupTime: any,
    returnDate: any,
    returnTime: any,
  ) {
    const config = {
    };

    const bookingFeeData = `?assetId=${assetID}&pickupDate=${pickupDate}&pickupTime=${pickupTime}&returnDate=${returnDate}&returnTime=${returnTime}`; // &surcharge=$Asset_Surcharge
    return http.get(`${process.env.REACT_APP_BASE_URL}booking/bill/fee${bookingFeeData}`, config);
  }

  async getAssetDetail(assetId: any) {
    const config = {};

    return http.get(`${process.env.REACT_APP_BASE_URL
    }asset/${assetId}/detail`, config);
  }

  async getAssetCalendar(
    assetId: string,
    startDate = '',
    endDate = '',
  ) {
    const url = `${process.env.REACT_APP_BASE_URL
    }asset/${assetId}/calendar?startDate=${startDate}&endDate=${endDate}`;

    return http.get(url);
  }

  async getAssetTypes() {
    return http.get(`${process.env.REACT_APP_BASE_URL}asset/type/list`);
  }
}

export const assetsApi = new AssetsApi();
