import type { ReactElement } from 'react';
import { Suspense } from 'react';
import DefaultLayout from 'pages/backend/layout/default-layout';
import { Route, Routes } from 'react-router-dom';
import type { IRouteItemProp } from 'core/interfaces/base';
import { partnerSidebarItems, partnerRouteItems } from './partner-router-helper';

export default function PartnerRouter(): ReactElement {
  return (
    <DefaultLayout sidebarItems={partnerSidebarItems}>
      <Suspense fallback={<div />}>
        <Routes>
          {partnerRouteItems.map((item: IRouteItemProp): ReactElement => (
            <Route path={item.path} element={item.element} key={item.id} />
          ))}
        </Routes>
      </Suspense>
    </DefaultLayout>
  );
}
