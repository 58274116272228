import type { ReactElement } from 'react';
import { Suspense } from 'react';
import DefaultLayout from 'pages/backend/layout/default-layout';
import { Route, Routes } from 'react-router-dom';
import type { IRouteItemProp } from 'core/interfaces/base';
import { customerSidebarItems, customerRouteItems } from './customer-router-helper';

export default function CustomerRouter():ReactElement {
  return (
    <DefaultLayout sidebarItems={customerSidebarItems}>
      <Suspense fallback={<div />}>
        <Routes>
          {customerRouteItems.map((item: IRouteItemProp): ReactElement => (
            <Route path={item.path} element={item.element} key={item.id} />
          ))}
        </Routes>
      </Suspense>
    </DefaultLayout>
  );
}
